import React from "react";
import { observer } from "mobx-react";
import ThreeColumnLayout from "../../common/layout/ThreeColumnLayout";
import JobMailerProfileForm from "./JobMailerProfileForm";
import { useJobMailerProfileStore } from "./JobMailerProfileStore";
import TopEmployersWidget from "../../widgets/TopEmployersWidget";
import AdvertisementBannerWidget from "../../widgets/AdvertisementBannerWidget";

const JobMailerProfilePage = observer(() => {
  const store = useJobMailerProfileStore();

  const {
    showExpiration,
    profile,
    classifications,
    regions,
    isWeeklyMailing,
    showSuccessMessage,
    error,
    submit,
    onDeactivate,
    updateSelectedClassifications,
    updateSelectedRegions,
    updateIsWeeklyMailing,
  } = store;

  return (
    <ThreeColumnLayout
      left={null}
      mid={
        <JobMailerProfileForm
          profile={profile}
          classifications={classifications}
          regions={regions}
          isWeeklyMailing={isWeeklyMailing}
          showExpiration={showExpiration}
          showSuccessMessage={showSuccessMessage}
          error={error}
          onSubmit={submit}
          onDeactivate={onDeactivate}
          onClassificationsSelect={updateSelectedClassifications}
          onIsWeeklyMailingToggle={updateIsWeeklyMailing}
          onRegionsSelect={updateSelectedRegions}
        />
      }
      right={
        <>
          <TopEmployersWidget />
          <AdvertisementBannerWidget />
        </>
      }
    />
  );
});

export default JobMailerProfilePage;
