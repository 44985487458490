import React, { useCallback } from "react";
import Styled from "./styled";

interface CheckboxProps {
  label: string;
  checked: boolean;

  onValueUpdate?(value: boolean): void;
}

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  checked,
  onValueUpdate,
}) => {
  const handleValueUpdate = useCallback(
    (e: any) => {
      onValueUpdate && onValueUpdate(e.target.checked);
    },
    [onValueUpdate]
  );

  return (
    <Styled.InputBase>
      <Styled.InputBaseField>
        <Styled.InputBaseLabel className="flex-center" style={{ height: "40px" }}>
          {label}
          <input
            className="checkbox"
            checked={checked}
            type="checkbox"
            onChange={handleValueUpdate}
            style={{ marginLeft: "5px" }}
          />
        </Styled.InputBaseLabel>
      </Styled.InputBaseField>
    </Styled.InputBase>
  );
};

export default Checkbox;
