import styled from "styled-components";

const JobActions = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const Styled = {
    JobActions,
};

export default Styled;
