import styled from "styled-components";

const Link = styled.a`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const Styled = {
  Link,
};

export default Styled;
