import React, { useEffect, useState } from "react";
import { useJobDetailsStore } from "./JobDetailsStore";
import { observer } from "mobx-react";
import ThreeColumnLayout from "../../common/layout/ThreeColumnLayout";
import JobDetailsContent from "./JobDetailsContent";
import JobDetailsNavigation from "./JobDetailsNavigation";
import TopEmployersWidget from "../../widgets/TopEmployersWidget";
import AdvertisementBannerWidget from "../../widgets/AdvertisementBannerWidget";
import { getWindowObj } from "../../../utils/window-utils";
import JobInfo from "./JobInfo";
import { Node, NodesGroup } from "../../types";

const JobDetailsPage = observer(() => {
  const store = useJobDetailsStore();

  const {
    job,
    jobMailerUrl,
    companyProfileUrl,
    aggregations,
    openJobsByCategory,
  } = store;
  const [similarJobsUrl, setSimilarJobsUrl] = useState("");
  
  useEffect(() => {
    setSimilarJobsUrl((getWindowObj()?.location.pathname || '/') + "similar");
  }, []);

  const categoryAggregations = aggregations.filter(node => node.fullname === "Category")
  const categoryGroups = categoryAggregations.length > 0 ? categoryAggregations[0].subnodes as NodesGroup[] : [];
  const categories = categoryGroups.flatMap(node => node)
    .flatMap(node => {
      const subnodes = node.subnodes as Node[];
      return subnodes && node.subnodes.length > 0 ? node.subnodes as Node[] : node
    });

  return (
    <>
      <JobDetailsNavigation
        similarJobsUrl={similarJobsUrl}
        categories={categories}
        openJobsByCategory={openJobsByCategory}
      />
      <ThreeColumnLayout
        left={<JobInfo job={job} jobMailerUrl={jobMailerUrl} />}
        mid={
          <>
            {job && (
              <JobDetailsContent
                job={job}
                jobMailerUrl={jobMailerUrl}
                companyProfileUrl={companyProfileUrl}
                similarJobsUrl={similarJobsUrl}
              />
            )}
            {!job && <div className="loading-center">Loading...</div>}
          </>
        }
        right={
          <>
            <TopEmployersWidget />
            <AdvertisementBannerWidget />
          </>
        }
      />
    </>
  );
});

export default JobDetailsPage;
