import React from "react";
import Styled from "./styled";

interface SvgIconProps {
  name: string;
  width?: number;
  height?: number;
  color?: string;
}

const SvgIcon: React.FC<SvgIconProps> = ({ name, width, height, color }) => {
  return (
    <Styled.SvgIcon width={width} height={height} color={color}>
      <use xlinkHref={`#${name}`} />
    </Styled.SvgIcon>
  );
};

export default SvgIcon;
