import React from "react";
import Styled from "./styled";

type JobInfoDetailProps = {
  icon: string;
  text: string;
  iconWidth: number;
  iconHeight: number;
}

const JobInfoDetail: React.FC<JobInfoDetailProps> = ({
  icon,
  text,
  iconWidth,
  iconHeight
}) => {
  return (
    <Styled.JobInfoDetail>
      <svg className="icon" style={{width: `${iconWidth}px`, height: `${iconHeight}px`}}>
        <use xlinkHref={`#${icon}`} />
      </svg>
      <span>{text}</span>
    </Styled.JobInfoDetail>
  );
};

export default JobInfoDetail;
