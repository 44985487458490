import styled from "styled-components";

const SvgIcon = styled.svg`
  width: ${(props) => (props.width ? `${props.width}px` : "24px")};
  height: ${(props) => (props.height ? `${props.height}px` : "24px")};
  fill: ${(props) => (props.color ? `${props.color}` : "#000")};;
`;

const Styled = {
  SvgIcon,
};

export default Styled;
