import styled from "styled-components";

const HorizontalTagsList = styled.div`
  display: flex;
  align-items: center;
`;

const HorizontalTagsListLabel = styled.div`
  margin: 0 12px;
`;

const HorizontalTagsListTags = styled.div`
  display: flex;
  gap: 12px;
`;


const HorizontalTagsListClearButton = styled.div`
  margin-left: 12px;
`;

const Styled = {
  HorizontalTagsList,
  HorizontalTagsListLabel,
  HorizontalTagsListTags,
  HorizontalTagsListClearButton,
};

export default Styled;
