import React, { useCallback, useContext } from "react";
import Wrapper from "../../common/Wrapper";
import Input from "../../common/Input/Input";
import Button from "../../common/Button";
import { ConfigContext } from "../../../providers/ConfigProvider";
import Styled from "./styled";

interface JobMailerSubscriptionFormProps {
  email: string;
  error?: string;
  withJob: boolean;
  showSubscriptionMessage: boolean;
  firstTimeSending: boolean;
  jobId: number | null;
  jobMailerUrl: string;

  updateEmail(email: string): void;

  validate(): boolean;

  onSubmit(email: string, sitekey: string): void;
}

const JobMailerSubscriptionForm: React.FC<JobMailerSubscriptionFormProps> = ({
  email,
  error,
  showSubscriptionMessage,
  firstTimeSending,
  withJob,
  jobId,
  jobMailerUrl,
  updateEmail,
  validate,
  onSubmit,
}) => {
  const siteKey = useContext(ConfigContext)?.siteKey!;

  const handleOnSubscribeClick = useCallback(() => {
    if (validate()) {
      onSubmit(email, siteKey);
    }
  }, [email, onSubmit, validate]);

  return (
    <>
      <Wrapper>
        <h1>Job Mailer</h1>
      </Wrapper>
      <Styled.JobMailerSubscriptionForm>
        {!showSubscriptionMessage && (
          <>
            {!!error && <div className="alert alert-danger">{error}</div>}
            <div>
              <p>
                Um den Job-Mailer zu abonnieren, oder Ihr bestehendes
                Job-Mail-Abo zu verwalten, geben Sie bitte Ihre Email Adresse an
                und klicken Sie auf [Abonnement verwalten]. Sie erhalten
                anschliessend eine Email mit dem Link zu ihrem persönlichen
                Profil (der Link ist eine Stunde gültig).
              </p>
            </div>
            <br />
            <div>
              <Input
                label="Email"
                type="email"
                value={email}
                onValueUpdate={updateEmail}
                onEnter={handleOnSubscribeClick}
              />
              <Button
                label="Abonnement verwalten"
                onClick={handleOnSubscribeClick}
                disabled={!email}
                eventId="Jobmail_Profile_Management"
                primary
              />
            </div>
            <div>
              <br />
              <p>
                Falls Sie sich über den Button [JOB-MAIL ERSTELLEN] der
                Detailansicht einer Vakanz abonnieren, werden die entsprechenden
                Klassifikationen/Regionen dieser Vakanz automatisch in Ihr
                Profil übernommen.
              </p>
              <br />
              <p>
                Falls Sie sich vom Job-Mailer abmelden wollen, können Sie dies
                auch via [Abonnement verwalten] tun. Den Link zur Abmeldung
                finden Sie dann auf der Profilseite ganz unten [Vom Job-Mailer
                abmelden].
              </p>
            </div>
          </>
        )}
        {showSubscriptionMessage && firstTimeSending && (
          <div className="alert alert-success">
            <span>
              Danke, dass Sie sich für unseren Job-Mailer interessieren. Ein
              Link zur Bestätigung Ihrer Registrierung wurde an die von Ihnen
              angegeben Email Adresse <strong>{email}</strong> geschickt. Bitte
              prüfen Sie Ihre Inbox. Sie haben dann auch die Möglichkeit ein
              persönliches Profil zu bearbeiten (Regionen/Themen).
            </span>
          </div>
        )}
        {showSubscriptionMessage && !firstTimeSending && (
          <div className="alert alert-success">
            {withJob && (
              <span>
                Eine E-Mail mit einem Link zur Bestätigung der neuen
                Abonnementeinstellungen wurde an die angegebene Adresse gesendet
                <strong>{email}</strong>.
              </span>
            )}
            {!withJob && (
              <span>
                Eine E-Mail, mit einem Link zum Verwalten des Abonnements, wurde
                an die angegebene Adresse geschickt <strong>{email}</strong>.
              </span>
            )}
          </div>
        )}
      </Styled.JobMailerSubscriptionForm>
    </>
  );
};

export default JobMailerSubscriptionForm;
