import React from "react";

import Styled from "./styled";
import SvgIcon from "../../../../common/SvgIcon";

type JobMailerLinkProps = {
    href?: string;
    target?: string;
    rel?: string;
    eventId?: string;
}

const JobMailerLink: React.FC<JobMailerLinkProps> = ({
    href,
    target,
    rel,
    eventId,
}) => {
    return (
        <Styled.JobMailerLinkWrapper>
            <Styled.JobMailerLink
                id={eventId}
                href={href}
                target={target}
                rel={rel}
            >
                <SvgIcon width={28} height={26} name="mail-circle" color="var(--button-color)" />
                <span style={{ marginLeft: "10px" }}>Job E-Mail erhalten</span>
            </Styled.JobMailerLink>
            <Styled.JobMailerLinkDescription>
                <SvgIcon width={22} height={16} name="return-arrow" color="#828282" />
                <Styled.JobMailerLinkDescriptionText>
                    Erhalte täglich oder wöchentlich Updates zu neu publizierten Stellen.
                </Styled.JobMailerLinkDescriptionText>
            </Styled.JobMailerLinkDescription>
        </Styled.JobMailerLinkWrapper>
    );
};

export default JobMailerLink;
