import React from "react";

import Styled from "./styled";

type ApplyLinkProps = {
    href?: string;
    target?: string;
    rel?: string;
    eventId?: string;
}

const ApplyLink: React.FC<ApplyLinkProps> = ({
    href,
    target,
    rel,
    eventId,
}) => {
    return (
        <Styled.ApplyLink
            id={eventId}
            href={href}
            target={target}
            rel={rel}
        >
            Bewerben
        </Styled.ApplyLink>
    );
};

export default ApplyLink;