import React, { useCallback } from "react";
import HorizontalScroll from "../../common/HorizontalScroll";
import { goBack } from "../../../utils/url-utils";
import Link from "../../common/Link";
import SvgIcon from "../../common/SvgIcon";
import { Node } from "../../types";

type JobDetailsNavigationProps = {
  similarJobsUrl: string;
  categories: Node[];

  openJobsByCategory(category: Node): void;
}

const JobDetailsNavigation: React.FC<JobDetailsNavigationProps> = ({
  similarJobsUrl,
  categories,
  openJobsByCategory,
}) => {
  const handleGoBack = useCallback(() => {
    goBack("/jobs");
  }, []);

  return (
    <HorizontalScroll>
      <span className="flex-center" style={{ gap: "35px" }}>
        <Link onClick={handleGoBack}>
          <SvgIcon name="arrowback" width={30} height={30} />
        </Link>
        <Link href="/jobs">Zurück zu allen Jobs</Link>
      </span>
      {categories && categories.length > 0 && (
        <span className="flex-center">
          Zu Jobs der gleichen Kategorie:
          {categories.map(category =>
            <span
              onClick={() => openJobsByCategory(category)}
              style={{
                marginLeft: "5px",
                padding: "6px 14px",
                borderRadius: "20px",
                border: "1px solid var(--button-color)",
                color: "var(--button-color)",
                fontSize: "13px",
                fontWeight: "bold",
                cursor: "pointer"
              }}>{category.label}
            </span>
          )}
        </span>
      )}
      <Link href={similarJobsUrl} rel="nofollow">
        Ähnliche Jobs
      </Link>
    </HorizontalScroll>
  );
};

export default JobDetailsNavigation;
