import styled, { css } from "styled-components";

const HorizontalScrollWrapper = styled.div`
  width: 100%;
  height: var(--head-height-mob);
  padding: 0 20px;
  background-color: white;

  // breakpoint(large)
  @media (min-width: 68.375em) {
    height: var(--head-height);
  }
`;

const HorizontalScrollItems = styled.ul`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const HorizontalScrollItem = styled.li<{ onRight?: boolean }>`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-right: 12px;
  font-size: 0.9em;

  // breakpoint(large)
  @media (min-width: 68.375em) {
    font-size: 1em;
  }

  a,
  a:visited,
  a:active {
    text-decoration: none;
    color: black;
  }

  a:hover {
    color: var(--button-color);
  }

  ${(props) =>
    props.onRight &&
    css`
      flex: 1;
      text-align: right;
      padding-right: 0;
    `}

  &:not(:first-child)::before {
    content: "";
    display: inline-block;
    position: relative;
    margin-right: 15px;
    height: 20px;
    width: 1px;
    background-color: #585858;
  }
`;

const HorizontalScroll = styled.div`
  max-width: 1025px;
  margin: 0 auto;
  margin-bottom: 0;
  padding-bottom: 0;
  height: var(--head-height-mob);
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-scrollbar: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: scroll;

  // breakpoint(large)
  @media (min-width: 68.375em) {
    height: var(--head-height);
    padding: 0 20px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Styled = {
  HorizontalScrollWrapper,
  HorizontalScroll,
  HorizontalScrollItems,
  HorizontalScrollItem,
};

export default Styled;
