import React from "react";
import { JobDetails } from "../../types";
import Styled from "./styled";
import JobInfoDetail from "./JobInfoDetail";
import { formatDateDots } from "../../../../utils/date-utils";
import { getJobLevelName, getJobTypeName } from "../../utils";
import JobActions from "../JobActions";

type JobInfoProps = {
  job: JobDetails;
  jobMailerUrl: string;
}

const JobInfo: React.FC<JobInfoProps> = ({
  job,
  jobMailerUrl,
}) => {
  let location = "";
  if (job.workplace?.address) {
    location += job.workplace.address + ",";
  }
  if (job.workplace?.zip) {
    location += " " + job.workplace.zip;
  }
  if (job.workplace?.city) {
    location += " " + job.workplace?.city;
  }
  let jobTypes = undefined;
  if (job.jobType) {
    jobTypes = job.jobType.map(type => getJobTypeName(type.replace("_", "").toLowerCase())).join(", ")
  }

  return (
    <Styled.JobInfoWrapper>
      <Styled.JobInfo>
        <Styled.JobInfoTitle>Stellenübersicht</Styled.JobInfoTitle>
        <Styled.JobInfoDetails>
          <JobInfoDetail icon="location" text={location} iconWidth={20} iconHeight={20} />
          {jobTypes && <JobInfoDetail icon="time" text={jobTypes} iconWidth={20} iconHeight={20} />}
          {job.jobLevel && <JobInfoDetail icon="job-level" text={getJobLevelName(job.jobLevel)} iconWidth={20} iconHeight={20} />}
          {job?.publishing?.from && <JobInfoDetail icon="calendar" text={formatDateDots(job.publishing.from)!} iconWidth={20} iconHeight={20} />}
        </Styled.JobInfoDetails>
      </Styled.JobInfo>
      <Styled.JobInfoActionsWrapper>
        <JobActions jobId={job.id} jobMailerUrl={jobMailerUrl} applicationUrlInfo={job.applicationUrlInfo} />
      </Styled.JobInfoActionsWrapper>
    </Styled.JobInfoWrapper>
  );
};

export default JobInfo;
