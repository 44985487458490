import styled from "styled-components";

const JobMailerLinkWrapper = styled.div`
`;

const JobMailerLink = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 25px;
    text-decoration: none !important;
    font-size: 18px;
    font-weight: bold;
    color: var(--button-color);
    background-color: #fff;
    border: 2px solid var(--button-color);

    &:hover {
        color: var(--button-color);
    }
`;

const JobMailerLinkDescription = styled.div`
    display: grid;
    grid-template-columns: 30px auto;
    align-items: normal;
    margin-top: 12px;
    font-size: 13px;
    color: #828282;
    padding-bottom: 4px;
`;

const JobMailerLinkDescriptionText = styled.span`
    position: relative;
    top: 4px;
`

const Styled = {
    JobMailerLinkWrapper,
    JobMailerLink,
    JobMailerLinkDescription,
    JobMailerLinkDescriptionText,
};

export default Styled;
