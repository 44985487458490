import styled from "styled-components";

const JobInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 20px;
  background-color: #fff;

  // breakpoint(large)
  @media (max-width: 1024px) {
    margin: 20px 0;
  }
`;

const JobInfo = styled.div`
`;

const JobInfoTitle = styled.h2`
  font-size: 1.1em;
  padding: 0;
  margin: 0;
`;

const JobInfoDetails = styled.div`
  // breakpoint(large)
  @media (max-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const JobInfoActionsWrapper = styled.div`
  // breakpoint(large)
  @media (max-width: 1024px) {
    display: none;
  }
`;

const Styled = {
  JobInfoWrapper,
  JobInfo,
  JobInfoTitle,
  JobInfoDetails,
  JobInfoActionsWrapper,
};

export default Styled;
