import React from "react";
import Styled from "./styled";
import ApplyLink from "./ApplyLink";
import JobMailerLink from "./JobMailerLink";

type JobActionsProps = {
    jobId: number;
    jobMailerUrl: string;
    applicationUrlInfo: { url?: string; mode: string };
}

const JobActions: React.FC<JobActionsProps> = ({
    jobId,
    jobMailerUrl,
    applicationUrlInfo
}) => {
    return (
        <Styled.JobActions>
            {applicationUrlInfo && applicationUrlInfo.mode !== "noapp" && (
                <>
                    {applicationUrlInfo.mode !== "mail" && (
                        <ApplyLink href={applicationUrlInfo.url} target="_blank" rel="noopener" eventId="Outbound_Link_Application"/>
                    )}
                    {applicationUrlInfo.mode === "mail" && (
                        <ApplyLink href={applicationUrlInfo.url} rel="nofollow" eventId="Email_Application_Form" />
                    )}
                </>
            )}
            {jobMailerUrl && (
                <JobMailerLink href={`/${jobMailerUrl}/${jobId}`} rel="nofollow" eventId="Jobmail_Registration" />
            )}
        </Styled.JobActions>
    );
};

export default JobActions;
