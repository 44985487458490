import React from "react";
import Link from "../../common/Link";
import Styled from "./styled";
import JobActions from "./JobActions";

interface JobDetailsContactsProps {
  jobId: number;
  jobMailerUrl: string;
  similarJobsUrl: string;
  applicationUrlInfo: { url?: string; mode: string };
}

const JobDetailsContacts: React.FC<JobDetailsContactsProps> = ({
  jobId,
  jobMailerUrl,
  similarJobsUrl,
  applicationUrlInfo,
}) => {
  return (
    <div>
      <Styled.JobDetailsContacts id="job-contacts" className="showsticky">
        <div className="allbut">
          <ul>
            {applicationUrlInfo && applicationUrlInfo.mode !== "noapp" && (
              <li className="left">
                {applicationUrlInfo.mode !== "mail" && (
                  <Link
                    href={applicationUrlInfo.url!}
                    target="_blank"
                    rel="noopener"
                    eventId="Outbound_Link_Application"
                  >
                    Bewerben
                  </Link>
                )}
                {applicationUrlInfo.mode === "mail" && (
                  <Link
                    href={applicationUrlInfo.url}
                    rel="nofollow"
                    eventId="Email_Application_Form"
                  >
                    Bewerben
                  </Link>
                )}
              </li>
            )}
            {jobMailerUrl && (
              <li className="mid">
                <Link
                  rel="nofollow"
                  href={`/${jobMailerUrl}/${jobId}`}
                  eventId="Jobmail_Registration"
                >
                  Job Mail erstellen
                </Link>
              </li>
            )}
            <li className="mid">
              <Link href={similarJobsUrl} rel="nofollow">
                Ähnliche Jobs
              </Link>
            </li>
          </ul>
        </div>
      </Styled.JobDetailsContacts>
      <Styled.JobDetailsContactsActionsWrapper>
        <JobActions jobId={jobId} jobMailerUrl={jobMailerUrl} applicationUrlInfo={applicationUrlInfo} />
      </Styled.JobDetailsContactsActionsWrapper>
    </div>
  );
};

export default JobDetailsContacts;
