import { JobAggregations } from "./types";
import { NodesGroup } from "../types";

export const createAggregations = (aggregations: any): JobAggregations => {
  const categories: NodesGroup[] = aggregations.verticals
    ?.filter((group: any[]) => group.length > 0)
    .map((group: any[]) =>
      group
        .filter((bucket: any) => !!bucket.key)
        .map((vertical: any) => {
          const node = {
            label: vertical.key,
            fullname: vertical.key,
            key: "category",
            number: vertical.doc_count,
            subnodes: [],
          };
          node.subnodes = vertical.verticals2.buckets
            .filter((bucket: any) => !!bucket.key)
            .map((vertical: any) => ({
              label: vertical.key,
              fullname: `${node.fullname}::${vertical.key}`,
              key: "category",
              number: vertical.doc_count,
              subnodes: [],
            }));
          return node;
        })
    );

  const companies = aggregations.company?.buckets
    .filter((bucket: any) => !!bucket.key)
    .map((company: any) => ({
      label: company.key,
      key: "company",
      number: company.doc_count,
      subnodes: [],
    }));

  const regions = aggregations.region?.buckets
    .filter((bucket: any) => !!bucket.key)
    .map((r: any) => ({
      label: r.key,
      key: "region",
      number: r.doc_count,
      subnodes: r.region2.buckets
        .filter((bucket: any) => !!bucket.key)
        .map((r: any) => ({
          label: r.key,
          key: "region",
          number: r.doc_count,
          subnodes: r.region3.buckets
            .filter((bucket: any) => !!bucket.key)
            .map((r: any) => ({
              label: r.key,
              key: "region",
              number: r.doc_count,
              subnodes: [],
            })),
        })),
    }));

  const jobTypes = aggregations.jobType?.buckets
    .filter((bucket: any) => !!bucket.key)
    .map((jobType: any) => ({
      label: getJobTypeName(jobType.key),
      fullname: jobType.key,
      key: "jobType",
      number: jobType.doc_count,
      subnodes: [],
    }));

  const jobLevels = aggregations.jobLevel?.buckets
    .filter((bucket: any) => !!bucket.key)
    .map((jobLevel: any) => ({
      label: getJobLevelName(jobLevel.key),
      fullname: jobLevel.key,
      key: "jobLevel",
      number: jobLevel.doc_count,
      subnodes: [],
    }));

  return { categories, companies, regions, jobTypes, jobLevels };
};

export const getJobTypeName = (key: string): string => {
  switch (key) {
    case "fulltime": {
      return "Full Time"; // Vollzeit
    }
    case "parttime": {
      return "Part Time"; // Teilzeit
    }
    case "remote": {
      return "Remote";
    }
    case "freelance": {
      return "Freelance";
    }
    case "apprenticeships": {
      return "Lehrstellen";
    }
    case "internships": {
      return "Praktika";
    }
    case "temporary": {
      return "Befristet";
    }
    case "lehrstellen-praktika": {
      return "Lehrstellen & Praktika";
    }
    default: {
      return key;
    }
  }
}

export const getJobLevelName = (key: string): string => {
  return key.charAt(0).toUpperCase() + key.slice(1);
}
