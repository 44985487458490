import styled from "styled-components";

const ApplyLink = styled.a`
    display: flex;
    justify-content: center;
    padding: 15px 25px;
    color: #fff;
    background-color: var(--button-color);
    font-weight: bold;
    letter-spacing: .1em;
    text-align: center;
    text-decoration: none !important;
    text-transform: uppercase;

    &:hover {
        background-color: var(--button-color-hover);
        color: #fff;
    }
`;

const Styled = {
    ApplyLink,
};

export default Styled;
