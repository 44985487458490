import React, { PropsWithChildren } from "react";
import Styled from "./styled";

interface LinkProps extends PropsWithChildren<{}> {
  href?: string;
  className?: string;
  target?: string;
  rel?: string;
  eventId?: string;

  onClick?(): void;
}

const Link: React.FC<LinkProps> = ({
  href,
  className,
  target,
  rel,
  eventId,
  onClick,
  children,
}) => {
  return (
    <Styled.Link
      href={href}
      className={className}
      target={target}
      rel={rel}
      onClick={onClick}
      id={eventId}
    >
      {children}
    </Styled.Link>
  );
};

export default Link;
