import React from "react";
import { action, computed, makeObservable, observable } from "mobx";
import axios from "axios";
import { getCookie } from "../../../utils/cookie-utils";

export class JobMailerSubscriptionStore {
  readonly jobId: number | null;
  readonly jobMailerUrl: string;

  @observable email: string = "";
  @observable error?: string;
  @observable showSubscriptionMessage: boolean = false;
  @observable firstTimeSending: boolean = false;

  constructor(jobId: number | null, jobMailerUrl: string) {
    makeObservable(this);
    this.jobId = jobId;
    this.jobMailerUrl = jobMailerUrl;
  }

  @computed get withJob() {
    return !!this.jobId;
  }

  @action
  readonly setEmail = (email: string) => {
    this.email = email;
  };

  @action
  readonly validate = () => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
      this.error = undefined;
      return true;
    } else {
      this.error = "Ungültige E-Mail";
      return false;
    }
  };

  @action
  readonly sendToken = async (email: string, siteKey: string): Promise<void | {status?: string}> => {
    const recaptcha = (window as any).grecaptcha
    recaptcha.enterprise.ready(() => {
      recaptcha.enterprise.execute(siteKey, { action: 'subscribe_job_mailer' })
        .then((token: string) => {
          const csrftoken = getCookie("csrftoken");
          return axios
            .post(
              "/job-mailer-api/send-token/",
              {
                email: email,
                jobId: this.jobId,
                captcha: token,
              },
              csrftoken
                ? {
                    headers: {
                      "X-CSRFToken": csrftoken,
                    },
                  }
                : undefined
            )
        })
        .then(
          action((response: any) => {
            if (response.data.success) {
              this.showSubscriptionMessage = true;
              this.firstTimeSending = response.data.firstTimeUser;
            }
          })
        )
        .catch((error: any) => {
          this.error = error.response.data.error;
        })
    });
  };
}

const storeContext = React.createContext<JobMailerSubscriptionStore | null>(
  null
);

export const JobMailerSubscriptionStoreProvider = ({
  jobId,
  jobMailerUrl,
  children,
}: React.PropsWithChildren<{ jobId: number | null; jobMailerUrl: string }>) => {
  const store = new JobMailerSubscriptionStore(jobId, jobMailerUrl);
  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
};

export const useJobMailerSubscriptionStore = () => {
  const store = React.useContext(storeContext);
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error(
      "useJobMailerSubscriptionStore must be used within a JobMailerSubscriptionStoreProvider."
    );
  }
  return store;
};
