import React from "react";
import { action, makeObservable, observable } from "mobx";
import { Node, NodesGroup } from "../../types";
import { goToUrl } from "../../../utils/url-utils";
import axios from "axios";
import { JobDetails } from "../types";
import { getJobLevelName, getJobTypeName } from "../utils";

export class JobDetailsStore {
  @observable job: JobDetails;
  @observable aggregations: Node[] = [];
  @observable jobMailerUrl: string;
  @observable companyProfileUrl: string;
  @observable showApplyForm: boolean = false;
  @observable error?: string;

  constructor(data: any) {
    makeObservable(this);
    this.job = data.job;
    this.jobMailerUrl = data.jobMailerUrl;
    this.companyProfileUrl = data.companyProfileUrl;

    const categories =
      data.aggregations?.verticals.filter((v: NodesGroup) => v.length > 0) ||
      [];
    const jobTypes = data.aggregations.jobType?.buckets
      .filter((bucket: any) => !!bucket.key)
      .map((jobType: any) => ({
        label: getJobTypeName(jobType.key),
        fullname: jobType.key,
        key: "jobType",
        number: jobType.doc_count,
        subnodes: [],
      }));
    const jobLevels = data.aggregations.jobLevel?.buckets
      .filter((bucket: any) => !!bucket.key)
      .map((jobLevel: any) => ({
        label: getJobLevelName(jobLevel.key),
        fullname: jobLevel.key,
        key: "jobLevel",
        number: jobLevel.doc_count,
        subnodes: [],
      }));
    
    const nodes = [];

    if (categories.length > 0) {
      nodes.push({
        label: "Category",
        fullname: "Category",
        showSubnodes: true,
        subnodes: categories.map((group: any[]) =>
          group
            .filter((bucket: any) => !!bucket.key)
            .map((vertical: any) => {
              const node = {
                label: vertical.key,
                fullname: vertical.key,
                key: "category",
                number: vertical.doc_count,
                subnodes: [],
              };
              node.subnodes = vertical.verticals2.buckets
                .filter((bucket: any) => !!bucket.key)
                .map((vertical: any) => ({
                  label: vertical.key,
                  fullname: `${node.fullname}::${vertical.key}`,
                  key: "category",
                  number: vertical.doc_count,
                  subnodes: [],
                }));
              return node;
            })
        ),
      })
    }

    if (jobTypes && jobTypes.length > 0) {
      nodes.push({
        label: "Job Type",
        fullname: "Job Type",
        showSubnodes: true,
        subnodes: jobTypes,
      });
    }

    if (jobLevels && jobLevels.length > 0) {
      nodes.push({
        label: "Job Level",
        fullname: "Job Level",
        showSubnodes: true,
        subnodes: jobLevels,
      });
    }

    this.aggregations = nodes;
  }

  @action openJobsByCategory = (category: Node) => {
    goToUrl(`/jobs/?classification=${category.label}`);
  };

  @action openApplyFormModal = () => {
    this.showApplyForm = true;
  };

  @action closeApplyFormModal = () => {
    this.showApplyForm = false;
  };

  @action processFormData = (
    siteKey: string,
    recipientEmail: string,
    senderEmail: string,
    sendCopy: boolean,
    cvFile: File,
    message: string,
    attachmentFiles?: File[]
  ) => {
    if (!this.validate(senderEmail, message)) {
      return;
    }
    const recaptcha = (window as any).grecaptcha
    recaptcha.enterprise.ready(() => {
      recaptcha.enterprise.execute(siteKey, { action: "send_application_email" })
        .then((token: string) => {
          const data = new FormData();
          data.append("captchaToken", token);
          data.append("recipientEmail", recipientEmail);
          data.append("senderEmail", senderEmail);
          data.append("sendCopy", "" + sendCopy);
          if (cvFile) {
            data.append("cv", cvFile, cvFile.name);
          }
          data.append(
            "subject",
            `Bewerbung als ${this.job.title || ""} bei ${
              this.job.company?.name || ""
            }`
          );
          if (message) {
            data.append("message", message);
          }
      
          if (attachmentFiles && attachmentFiles.length > 0) {
            attachmentFiles.forEach((file, index) => {
              data.append(`attachments[]`, file, file.name);
            });
          }
      
          axios
            .post("/jobs-api/send-application-email/", data)
            .then(() => {
              this.error = undefined;
              this.closeApplyFormModal();
            })
            .catch((error) => {
              this.error = error.response.data.error;
            });
        });
      });
  };

  @action
  readonly validate = (email: string, message: string) => {
    this.error = undefined;
    let errorMessage = "";

    if (!email) {
      errorMessage += `${
        errorMessage.length > 0 ? "\n" : ""
      }E-Mail ist erforderlich.`;
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      errorMessage += `${errorMessage.length > 0 ? "\n" : ""}Ungültige E-Mail.`;
    }

    if (!message) {
      errorMessage += `${
        errorMessage.length > 0 ? "\n" : ""
      }Mitteilung darf nicht leer sein.`;
    }

    if (errorMessage.length > 0) {
      this.error = errorMessage;
      return false;
    } else {
      return true;
    }
  };
}

const storeContext = React.createContext<JobDetailsStore | null>(null);

export const JobDetailsStoreProvider = ({
  data,
  children,
}: React.PropsWithChildren<{ data: any }>) => {
  const store = new JobDetailsStore(data);
  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
};

export const useJobDetailsStore = () => {
  const store = React.useContext(storeContext);
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error(
      "useJobDetailsStore must be used within a JobStoreProvider."
    );
  }
  return store;
};
