import React, { useCallback } from "react";
import HorizontalScroll from "../HorizontalScroll";
import Tag from "./Tag";
import Link from "../Link";
import SvgIcon from "../SvgIcon";
import Styled from "./styled";

type TagsListProps = {
  names: string[];
  icon?: string;
  label?: string;
  removeLabel?: string;

  onTagClick?(name: string): void;

  onRemoveTagClick(name: string): void;

  onClearAllClick(): void;
}

const HorizontalTagsList: React.FC<TagsListProps> = ({
  names,
  icon,
  label,
  removeLabel,
  onTagClick,
  onRemoveTagClick,
  onClearAllClick,
}) => {
  const handleTagClick = useCallback(
    (name: string) => {
      onTagClick && onTagClick(name);
    },
    [onTagClick]
  );

  return (
    <HorizontalScroll lastOnRight>
      <Styled.HorizontalTagsList>
        {icon && <SvgIcon name={icon} />}
        <Styled.HorizontalTagsListLabel>{label}</Styled.HorizontalTagsListLabel>
        <Styled.HorizontalTagsListTags>
          {names.map((name) => (
            <Tag
              key={name}
              name={name}
              onTagClick={handleTagClick}
              onTagClose={onRemoveTagClick}
            />
          ))}
        </Styled.HorizontalTagsListTags>
        <Styled.HorizontalTagsListClearButton>
          <Link onClick={onClearAllClick}>{removeLabel || "Alle löschen"}</Link>
        </Styled.HorizontalTagsListClearButton>
      </Styled.HorizontalTagsList>
    </HorizontalScroll>
  );
};

export default HorizontalTagsList;
