import styled from "styled-components";

const JobInfoDetail = styled.div`
    display: flex;
    align-items: center;
    gap: 11px;
    margin-top: 16px;
`;

const Styled = {
  JobInfoDetail,
};

export default Styled;
